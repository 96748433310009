import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { GrDocumentCsv } from "react-icons/gr";
import { API, Headers } from "../../../Config";
import styles from "../styles";
import ReactToPrint from "react-to-print";
import DeliveryListgif from "../../.././Assets/Images/deliverylist.gif";
import {
  AiFillPrinter,
  AiOutlineCloseCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DeliveryList from "../PrintDesign/DeliveryList";
import ExcelDeliveryList from "../ExportExcel/ExcelDeliveryList";

function PrintDeliveryListModal(props) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [singlebox, setSinglebox] = useState([]);
  const [multipleboxes, setMultipleboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [excel, setExcel] = useState(false);
  useEffect(() => {
    loadBoxesViaAwb();
  }, []);

  const loadBoxesViaAwb = () => {
    const AWBID = props.selected && props.selected.id;
    // let URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + `byColln/${AWBID}`;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
          SortBoxes(data);
        } else {
          setIsLoading(false);
          message.error("Oops.Something went");
        }
      });
  };

  const SortBoxes = async (boxes) => {
    let newArr = boxes;
    let sorted_arr = newArr.slice().sort();
    let results = [];
    let Newresults = [];
    for (let i = 0; i < sorted_arr.length; i++) {
      if (sorted_arr[i + 1]?.collection_id == sorted_arr[i]?.collection_id) {
        results.push(sorted_arr[i]);
      } else {
        Newresults.push(sorted_arr[i]);
      }
    }
    setMultipleboxes(results);
    // console.log("setMultipleboxes======>", results);
    setSinglebox(Newresults);
    // console.log("setSinglebox=======>", Newresults);
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        footer={null}
        onCancel={props.onClose}
        width={data && data.length ? 1100 : 300}
      >
        {isLoading ? (
          <div className="print-manifest-box1">
            <img src={DeliveryListgif} className="print-document-loader" />
            <div>Downloading Details....</div>
          </div>
        ) : data && data.length === 0 ? (
          <>
            <div className="print-manifest-box1">
              <br />
              <AiOutlineCloseCircle size={50} color={"red"} />
              <div style={{ margin: 20 }} />
              <div>Sorry No document Find.</div>
              <div style={{ margin: 30 }} />
              <Button type="primary" onClick={props.onClose}>
                Close
              </Button>
            </div>
          </>
        ) : (
          <div>
            <h5 className="PrintLabelModal-txt1">Print Delivery list</h5>
            <ReactToPrint
              trigger={() => (
                <Button
                  style={styles.buttons}
                  type="primary"
                  icon={<AiFillPrinter style={styles.buttonsIcon} />}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
            {/* <PDFDownloadLink
              document={<DeliveryList data={data} awb={props.selected} />}
              fileName={`${props.selected.awb_no}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? null : (
                  <Button
                    style={styles.buttons}
                    icon={<AiOutlineFilePdf style={styles.buttonsIcon} />}
                  >
                    Download PDF
                  </Button>
                )
              }
            </PDFDownloadLink> */}
            <Button
              icon={<GrDocumentCsv style={styles.buttonsIcon} />}
              onClick={() => setExcel(true)}
            >
              Download as Excel
            </Button>

            <br />
            <br />

            <div className="print-deliver-list-page" ref={componentRef}>
              <div className="print-deliver-list-txt1">
                {props.selected && props.selected.orijin_airport_name} -
                {props.selected && props.selected.destination_airport_name} - (
                {`${props.selected.airline}-${props.selected.awb_no}`}) (
                {data && data.length} PCS)
              </div>
              <table className="print-deliver-list-table">
                <tr>
                  <th
                    className="print-deliver-list-table-td"
                    style={{ width: "120px" }}
                  >
                    <div className="print-deliver-list-txt2">BOX NO</div>
                  </th>
                  <th className="print-deliver-list-table-td">
                    <div className="print-deliver-list-txt2">REFERENCE</div>
                  </th>
                  <th
                    className="print-deliver-list-table-td"
                    style={{ width: "5%" }}
                  >
                    <div className="print-deliver-list-txt2">Pcs</div>
                  </th>
                  <th
                    className="print-deliver-list-table-td"
                    style={{ width: "7%" }}
                  >
                    <div className="print-deliver-list-txt2">WEIGHT</div>
                  </th>

                  <th className="print-deliver-list-table-td" colSpan={3}>
                    <div className="print-deliver-list-txt2">
                      SHIPPER ADDRESS
                    </div>
                  </th>
                  <th className="print-deliver-list-table-td" colSpan={3}>
                    <div className="print-deliver-list-txt2">
                      CONSIGNEE ADDRESS
                    </div>
                  </th>
                  <th className="print-deliver-list-table-td">
                    <div className="print-deliver-list-txt2">STATE</div>
                  </th>
                  <th className="print-deliver-list-table-td">
                    <div className="print-deliver-list-txt2">DIST</div>
                  </th>
                </tr>

                {data && data.length
                  ? singlebox.map((item, index) => {
                      let desc = " ";
                      let totalWeight = 0;
                      let ref = " ";
                      let pieces = 0;
                      try {
                        item.collection.boxes.map(async (item2, index2) => {
                          if (item?.airway_bill_no === item2?.airway_bill_no) {
                            desc += item2?.box_number + ",";
                            ref += item2?.box_ref_no
                              ? item2?.box_ref_no + ","
                              : item2?.referance_details + ",";
                            totalWeight += item2?.chargable_weight;
                            pieces++;
                          }
                        });
                      } catch (err) {
                        console.log(err);
                      }

                      return (
                        <tr>
                          <td
                            className="print-deliver-list-table-td"
                            style={{ width: "120px" }}
                          >
                            <div
                              className="print-deliver-list-txt2"
                              style={{ wordWrap: "break-word" }}
                            >
                              <div>{desc}</div>
                            </div>
                          </td>
                          <td className="print-deliver-list-table-td">
                            <div className="print-deliver-list-txt2">
                              {/* {item.referance_details &&
                              item.referance_details != "NULL"
                                ? item.referance_details
                                : ""} */}
                              {ref}
                            </div>
                          </td>
                          <td className="print-deliver-list-table-td">
                            <div className="print-deliver-list-txt2">
                              {pieces}
                            </div>
                          </td>
                          <td className="print-deliver-list-table-td">
                            <div className="print-deliver-list-txt2">
                              {totalWeight.toFixed(2)}
                            </div>
                          </td>

                          <td
                            className="print-deliver-list-table-td"
                            colSpan={3}
                          >
                            <div className="print-deliver-list-txt2">
                              {item.collection.sender_name} , PHONE :
                              {item.collection.sender_phone},
                              {item.collection.sender_address}
                            </div>
                          </td>
                          <td
                            className="print-deliver-list-table-td"
                            colSpan={3}
                          >
                            <div className="print-deliver-list-txt2">
                              {item.collection.reciver_name},
                              {item.collection.reciver_address},
                              {item.collection.reciver_distrcit},
                              {item.collection.reciver_state}, PIN:{" "}
                              {item.collection.reciver_pincode}, PHONE:{" "}
                              {item.collection.reciver_phone},
                              {item.collection.reciver_altphone},
                              {item.collection.recivercountries &&
                                item.collection.recivercountries.name}
                            </div>
                          </td>
                          <td className="print-deliver-list-table-td">
                            <div className="print-deliver-list-txt2">
                              {item.collection.reciver_state}
                            </div>
                          </td>
                          <td className="print-deliver-list-table-td">
                            <div className="print-deliver-list-txt2">
                              {item.collection.reciver_distrcit}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
            </div>
            <br />
            <br />
            <Row>
              <Col sm="8" xs="12"></Col>
              <Col sm="4" xs="12">
                <Row>
                  <Col sm="6" xs="12">
                    <Button onClick={() => props.onClose()} block size="large">
                      Cancel
                    </Button>
                  </Col>
                  <Col sm="6" xs="12">
                    <ReactToPrint
                      trigger={() => (
                        <Button type="primary" size="large" block>
                          Print
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      {excel ? (
        <ExcelDeliveryList
          selected={props.selected}
          visible={excel}
          onClose={() => setExcel(false)}
        />
      ) : null}
    </div>
  );
}

export default PrintDeliveryListModal;
