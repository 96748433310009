import { Modal, Tabs, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { API, Headers } from "../../Config";

import { v4 as uuidv4 } from "uuid";
import { clear, save } from "../../Redux/Slices/CollectionSlice";

import { POST } from "../../Config/ApiRequests";
import BoxForm from "./BoxForm";
import CustomerForm from "./CustomerForm";
import FullDetails from "./FullDetails";
import OtherDetails from "./OtherDetails";

var width = window.innerWidth;
function FormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();
  const Collection = useSelector((state) => state.Collection);

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [key, setKey] = useState(1);
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const [obje, setObje] = useState(Collection.data);
  const [CustomerData, setCustomerData] = useState({});
  const [BoxData, setBoxData] = useState({});
  const [PaymentData, setPaymentData] = useState({});
  const [Otherdata, setothersData] = useState({});

  const [INR, setInr] = useState(0);
  const [USD, setUsd] = useState(0);
  const [SAR, setSar] = useState(0);

  const [boxdataFilled, setBoxDataFilled] = useState(false);

  const [custdataFilled, setCustDataFilled] = useState(false);
  const [otherdataFilled, setOtherDataFilled] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const [isLoading2, setIsLoading2] = useState(false);
  const [exchangeRates, setexchangeRates] = useState([]);
  const [rates, setRates] = useState({});
  const [meta, setMeta] = useState({});

  useEffect(() => {
    // GetCurrancy();
    getExchangeRates(1, 10);
  }, []);

  const getExchangeRates = async (_page, _take) => {
    try {
      setIsLoading2(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: _page,
        take: _take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setexchangeRates(excData?.data[0]);
        setRates(excData?.data[0]);
        console.log("excData === ", excData?.data);
        setInr(excData?.data[0]?.sar_inr);
        setUsd(excData?.data[0]?.sar_usd);
        setSar(excData?.data[0]?.inr_sar);
        setMeta(excData?.meta);
        setIsLoading2(false);
      } else {
        message.error("No data found");
        setIsLoading2(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setIsLoading2(false);
    }
  };

  const CurrancyRate = async (type) => {
    const URL = API.BASR_URL + API.EXCHANGE_RATE + type;
    try {
      const response = await axios.get(URL, {});
      console.log(response);
      if (response?.status) {
        const data = response?.data?.data?.rate;
        setUsd(data);
        return Number(data);
      } else {
        message.error("Oops.Something went");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // changes by JIS - 8/4/2024
  const GetCurrancy = async () => {
    // let INR = await CurrancyRate("SAR_INR");
    const INR = await getExchangeRates("SAR_INR");
    console.log("INR ---> ", INR);
    setInr(INR);
    // let INR = RATES.SAR_INR;
    // setInr(INR);
    // let USD = await CurrancyRate("SAR", "USD");
    // let USD = RATES.SAR_USD;
    // let USD = await CurrancyRate("SAR_USD");

    const USD = await getExchangeRates("SAR_USD");
    console.log("USD ---> ", USD);
    setUsd(USD);
    // setUsd(USD);
    // let SAR = await CurrancyRate("INR", "SAR");
    // let SAR = RATES.INR_SAR;
    // let SAR = await CurrancyRate("INR_SAR");
    const SAR = await getExchangeRates("INR_SAR");
    console.log("SAR ---> ", SAR);
    setSar(SAR);
    // setSar(SAR);
  };

  const BoxNext = (count, data) => {
    setBoxData(data);
    JoinDATA();
    setBoxDataFilled(true);
  };

  const CustomerNext = (count, data) => {
    setCustomerData(data);
    JoinDATA();
    setCount(count);
    setCustDataFilled(true);
  };

  const PaymentNext = (count, data) => {
    setPaymentData(data);
    JoinDATA();
    setCount(count);
  };

  const OthersNext = (count, data) => {
    setothersData(data);
    JoinDATA();
    setCount(count);
    setOtherDataFilled(true);
  };

  const OthersFinish = (count, data) => {
    setothersData(data);
    JoinDATA();
    if (boxdataFilled && custdataFilled && otherdataFilled) {
      setShowFullDetails(true);
    }
    setCount(3);
  };

  const fullDetailsFinish = (count, data) => {
    setLoading(true);
    setShowFullDetails(false);
    Finish(data);
  };
  let branch = {
    branch_id: Number(Auth.branch_id),
  };
  console.log("1233333333333333333333333333333333", Auth.branch_id);
  const JoinDATA = () => {
    let objs = {
      uuid:
        Collection.data && Collection.data.uuid ? Collection.data.uuid : uuid,
    };

    var obj = {
      ...Collection.data,
      ...CustomerData,
      ...BoxData,
      ...PaymentData,
      ...Otherdata,
      ...objs,
      ...branch,
    };
    setObje(obj);
    setTimeout(() => {
      dispatch(save(obj));
    }, 10);
  };
  const Finish = (data) => {
    var obj = {
      data: {
        ...obje,
        ...CustomerData,
        ...branch,
        ...BoxData,
        ...PaymentData,
        ...data,
      },
    };
    let URL =
      props.mode === "post"
        ? "pickupCollectionsNew/createCollection"
        : API.PICKUPCOLLECTIONS_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
      taxgoToken: "",
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          dispatch(clear());
          props.onClose();
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const { TabPane } = Tabs;

  const onChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  return (
    <Modal
      placement="right"
      title={
        <div className="newFormModalTitle">
          {props.mode === "post" ? "New" : "Update"} {"   "}Collection Form
        </div>
      }
      footer={false}
      onCancel={() => props.onClose()}
      onClose={() => props.onClose()}
      visible={props.visible}
      width={width / 1.15}
      maskClosable={false}
      closeIcon={<IoIosClose color="#fff" size={30} />}
    >
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        activeKey={activeTab}
        centered
        style={{ marginBottom: 0 }}
      >
        <TabPane tab={<span>Box Info</span>} key="1">
          <BoxForm
            uuid={uuid}
            count={count}
            key={key}
            data={obje}
            item={props.item}
            mode={props.mode}
            users={props.users}
            servicetype={props.servicetype}
            boxtype={props.boxtype}
            curreny={{ INR, USD, SAR }}
            onClose={() => props.onClose()}
            next={(count, data) => BoxNext(count, data)}
            onchange={(count, data) => BoxNext(count, data)}
            onTabchange={(key) => onChange(key)}
          />
        </TabPane>
        <TabPane tab={<span>Customer Info</span>} key="2">
          <CustomerForm
            uuid={uuid}
            count={count}
            customers={props.customers}
            countries={props.countries}
            recievers={props.recievers}
            senders={props.senders}
            data={obje}
            item={props.item}
            mode={props.mode}
            next={(count, data) => CustomerNext(count, data)}
            onchange={(count, data) => CustomerNext(count, data)}
            onTabchange={(key) => onChange(key)}
          />
        </TabPane>
        <TabPane tab={<span>Other Info</span>} key="3">
          <OtherDetails
            uuid={uuid}
            loading={loading}
            count={count}
            data={obje}
            item={props.item}
            mode={props.mode}
            users={props.users}
            agents={props.agents}
            pickrequest={props.pickrequest}
            OnBack={(count) => setCount(count)}
            next={(count, data) => OthersFinish(count, data)}
            onchange={(count, data) => OthersNext(count, data)}
            onTabchange={(key) => onChange(key)}
          />
        </TabPane>
        <TabPane
          tab={<span>Overview</span>}
          key="4"
          disabled={!boxdataFilled && !custdataFilled && !otherdataFilled}
        >
          <FullDetails
            loading={loading}
            fullData={obje}
            count={count}
            onTabchange={(key) => onChange(key)}
            onDiscard={(c) => {
              setCount(c);
            }}
            next={(count, data) => fullDetailsFinish(count, data)}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}
export default FormModal;
