import React from "react";
import moment from "moment";
import { Table } from "antd";

const SalesExpenceTable = (props) => {
  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return <div>{moment(record?.date).format("MMM Do YY")}</div>;
      },
    },
    {
      title: "EXPENSES",
      dataIndex: "expense",
      key: "expense",
      render: (text, record) => <div>{record?.head}</div>,
    },
    {
      title: "NOTE",
      dataIndex: "note",
      key: "note",
      render: (text, record) => <div>{record?.note}</div>,
    },
    {
      title: "AMOUNT",
      dataIndex: "rate",
      key: "rate",
      render: (text, record) => <div>{record?.amount}</div>,
    },
    {
      title: "EXPENSE BY",
      dataIndex: "expance_user",
      key: "expance_user",
      render: (text, record) => <div>{record?.expance_user?.name}</div>,
    },
  ];

  return (
    <div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props?.expense}
        pagination={false}
        size="small"
        rowKey={(record) => record.id}
      />
    </div>
  );
};

export default SalesExpenceTable;
