import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import CollectionSlice from "./CollectionSlice";
import TaxgoSlice from "./TaxgoSlice";
// import TaxgoSalesInvoiceSlice from "./TaxgoSales";
const Slices = combineReducers({
  Auth: AuthSlice.reducer,
  Collection: CollectionSlice.reducer,
  TaxgoSlice: TaxgoSlice.reducer,
  // TaxgoSalesInvoiceSlice: TaxgoSalesInvoiceSlice.reducer,
});
export default Slices;
