import { useSelector } from "react-redux";
import API from "./API";

const GET = async (url, params) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASR_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url, body) => {
  // const User = Store.getState().User;
  // const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(API.BASR_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { GET, POST };
