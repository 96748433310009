import React, { useEffect, useState, useRef } from "react";
import { message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
import LoadingBox from "../../Components/LoadingBox";
import { API, Headers } from "../../Config";
import { GET } from "../../Config/ApiRequests";
import PageHeader from "../../Routes/PageHeader";
import SalesDataTable from "./dataTable";
import MenuBarSales from "./menu";
import SalesExpenceTable from "./expenceTable";
import SalespaymentModal from "./paymentModal";
import "./styles.css";
const SalesReport = () => {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [expense, setExpences] = useState();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [dateFrom, setDateFrom] = useState("");
  const [search, setSearch] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);

  const componentRef = useRef();

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded?.filter((item) => item !== panel));
    }
  };

  const onFinish = async (val) => {
    const date = moment(val?.date).toISOString() || "";
    const searchId = val?.search || "";
    setSearch(searchId);
    setDateFrom(date);
    await loadUsersDatas(page, take, date, searchId);
    if (searchId) {
      await loadData(searchId);
    }
  };

  const handleSubmit = () => {
    setSelectedRows(selectedRows);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadUsersDatas = async (p, t, date, searchValue) => {
    try {
      setLoading(true);
      let URL = API.BOXES_VIA_BRANCH_BY_BOXDETAILS + `${Number(Auth.id)}`;
      let query = `?order=DESC&page=${p}&take=${t}`;
      if (date) query += `&date=${date}`;
      if (searchValue) query += `&search=${searchValue}`;
      await axios
        .get(API.BASR_URL + URL + query, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setData(data);
            setMeta(data?.meta);
            message.success("Success");
          }
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    loadUsersDatas(page, take, dateFrom, search, allUsers);
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const api = API.ALL_USERS;
      const users = await GET(api, null);
      if (users?.status) {
        setAllUsers(users?.data);
        setLoading(false);
      } else {
        setAllUsers([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const AccountsDropdown = async () => {
    await axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: new Headers(),
      })

      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };

  useEffect(() => {
    AccountsDropdown();
    getAllUsers();
  }, [search]);

  const loadData = async (searchName) => {
    setLoading(true);
    let api = `${API.BASR_URL}expance_entry/byUser/${searchName}?order=DESC&page=1&take=30`;

    await fetch(api, {
      method: "get",
      headers: Headers(),
    })
      .then((data) => data.json())
      .then((exp) => {
        setExpences(exp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("expance_entry Error ===>>", error);
      });
  };

  const totalExpense = expense?.reduce(
    (acc, item) => acc + parseFloat(item?.amount),
    0
  );

  const totalBoxCharge = data?.data?.reduce(
    (acc, item) => acc + parseFloat(item?.total_charge),
    0
  );

  const handleBeforePrint = () => {
    setIsPrinting(true);
    setExpanded(["panel1", "panel2", "panel3"]);
    return new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
    setExpanded(["panel1"]);
  };



  return (
    <div>
      <PageHeader PageName={"Report"} breadScrum={"Admin / Report / Sales"} />
      <MenuBarSales onFinish={(val) => onFinish(val)} allUsers={allUsers} />
      <div className="text-end no-print">
        <ReactToPrint
          trigger={() => (
            <Button
              size="small"
              type="primary"
              icon={<PrinterOutlined />}
              style={{ marginLeft: 8 }}
            >
              Print Statement
            </Button>
          )}
          content={() => componentRef.current}
          onBeforeGetContent={handleBeforePrint}
          onAfterPrint={handleAfterPrint}
        />
      </div>
      <div className="d-flex justify-content-end p-3" />
      <div ref={componentRef} className="p-3">
        <div className="print-header">SAHARI CARGO AND COURIER</div>
        <div className="my-3">Daily Closing Report</div>
        {loading ? (
          <LoadingBox />
        ) : (
          <>
            <Accordion
              expanded={expanded.includes("panel1") || isPrinting}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand_icon" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="new-collection-form-txt1">Boxes Details</div>
              </AccordionSummary>
              <AccordionDetails>
                <SalesDataTable
                  data={data?.data}
                  PageOnchange={(page, take) => pagination(page, take)}
                  meta={meta}
                  // onSelect={setSelectedRows}
                  onSelect={(selectedRows) => setSelectedRows(selectedRows)}
                  onAcounts={setAccounts}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded.includes("panel2") || isPrinting}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand_icon" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="new-collection-form-txt1">Expense Details</div>
              </AccordionSummary>
              <AccordionDetails>
                <SalesExpenceTable expense={expense} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded.includes("panel3") || isPrinting}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand_icon" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="new-collection-form-txt1">Summary Details</div>
              </AccordionSummary>
              <AccordionDetails>
                <div>Total Box Amount : {totalBoxCharge}</div>
                <div>Total Expense : {totalExpense}</div>
                <hr />
                <div>
                  Closing Balance :{" "}
                  <strong>{totalBoxCharge - totalExpense}</strong>
                </div>
              </AccordionDetails>
            </Accordion>
            <br />

            <Row className="no-print">
              <Col sm="6" xs="6" />
              <Col sm="3" xs="3">
                <Button block size="large">
                  Cancel
                </Button>
              </Col>
              <Col sm="3" xs="3">
                <Button
                  loading={loading}
                  block
                  size="large"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            {isModalOpen && (
              <SalespaymentModal
                visible={isModalOpen}
                onCancel={handleCancel}
                selectedRows={selectedRows}
                accounts={accounts}
                reload={onFinish}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SalesReport;
