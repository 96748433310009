import React, { useState, useEffect } from "react";
import { Drawer, Form, Button, Input, message, Space, Select } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import API from "../../Config/API";
import { Headers } from "../../Config";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import style from "./style";

var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
const { Option } = Select;

function RegisterModal(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [branches, setbranches] = useState([]);

  useEffect(() => {
    BranchesDropdown();
  }, []);

  const BranchesDropdown = () => {
    axios
      .get(API.BASR_URL + API.BRANCHES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setbranches(res.data);
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      name: values.name,
      email: values.email,
      user_name: values.user_name,
      password: values.password,
      mobile: values.mobile,
      branch_id: parseInt(values.branch_id),
      country: 0,
      place: "test",
      address: values.address,
      type: "test",
      role: ["user"],
      active: false,
    };
    axios
      .post(API.BASR_URL + API.SIGNUP, obj, {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        setLoading(false);
        if (response.status === 201) {
          props.onClose();
          // dispatch(login(response.data));
          message.success("Registerd successfully.");
          // props.history.push("/auth/home");
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error.response.data.errors[0].message);
      });
  };

  return (
    <Drawer
      placement="right"
      title={<span className="modalTitle">Register Now</span>}
      // width={720}
      width={innerwidth ? width : width / 2}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label="Full Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" style={style.Input} />
              </Form.Item>
              <Form.Item
                name="mobile"
                label="Mobile Number"
                rules={[{ required: true, message: "required" }]}
              >
                <Input
                  placeholder="Enter Phone Number"
                  type={"number"}
                  style={style.Input}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="user_name"
                label="User Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter User Name" style={style.Input} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "required" }]}
              >
                <Input
                  placeholder="Enter email"
                  type={"email"}
                  style={style.Input}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="6" xs="12">
              {" "}
              <Form.Item
                name="address"
                label="Full Address"
                rules={[{ required: true, message: "required" }]}
              >
                <Input.TextArea
                  placeholder="Enter Address"
                  style={style.Input}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="branch_id"
                label="Branch"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  bordered={false}
                  className="RegisterModal-Select"
                  placeholder="Select Branch"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={style.Input}
                >
                  {branches.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                  {
                    min: 6,
                    message:
                      "password must be longer than or equal to 6 characters",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter password"
                  style={style.Input}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="confirm"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="confirm your password"
                  style={style.Input}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button onClick={() => props.onClose()}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Space>
        </Container>
      </Form>
    </Drawer>
  );
}
export default withRouter(RegisterModal);
