import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  DatePicker,
  Select,
  Button,
  Table,
  message,
  Tabs,
  Typography,
  Input,
} from "antd";
import moment from "moment";
import { API, Headers } from "../../Config";
import axios from "axios";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const AwbReports = () => {
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const [form] = Form.useForm();
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {}, []);

  // } else {
  //   // Handle the case when dateRange is not properly set
  //   console.error("Invalid dateRange:", dateRange);
  // }
  // rest of the code ...

  const onFinish = async (values) => {
    const { dateRange, awb_no } = values;

    setLoading(true);
    let obj = {};

    if (dateRange && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      const formattedStartDate = startDate
        ? startDate.startOf("day").toISOString()
        : null;
      const formattedEndDate = endDate
        ? endDate.endOf("day").toISOString()
        : null;

      obj = {
        date_from: formattedStartDate,
        date_to: formattedEndDate,
      };
    } else if (awb_no) {
      obj = {
        awb_no: awb_no,
      };
    }

    axios({
      method: "post",
      url: API.BASR_URL + API.AWB_PAYMENT_ACTIONS + `awbReport`,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setFilteredDataSource(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops. Something went wrong");
        console.log(error);
      });
  };

  const columns = [
    {
      width: "120px",
      title: "Date",
      dataIndex: "date",
      key: "date",

      render: (text, record, index) => (
        <div className="data-table-items">
          {moment(record.date).format("DD-MM-YYYY")}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      width: "250px",
      title: "AWB",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Chargable Weight",
      dataIndex: "chargable_weight",
      key: "chargable_weight",
    },
    {
      title: "origin",
      dataIndex: "orijin",
      key: "orijin",
    },
    {
      title: "destination",
      dataIndex: "destination",
      key: "destination",
    },
    // {
    //   title: "Inbound Amount",
    //   dataIndex: "inboundAmount",
    //   key: "inboundAmount",
    // },
    {
      title: "Bound",
      dataIndex: "bound",
      key: "bound",
    },

    {
      title: "Income",
      dataIndex: "box_charge",
      key: "box_charge",
      render: (text, record, index) => (
        <div className="data-table-items">
          {Number(record.box_charge).toFixed(2)}
        </div>
      ),
    },
    {
      title: "Expence",
      dataIndex: "amount",
      key: "amount",
      render: (text, record, index) => (
        <div className="data-table-items">
          {Number(record.amount).toFixed(2)}
        </div>
      ),
    },
    {
      title: "Profit",
      dataIndex: "balance",
      key: "balance",
      render: (text, record, index) => (
        <div className="data-table-items">
          {Number(record.balance).toFixed(2)}
        </div>
      ),
    },
  ];

  // // Prepare data for the table
  // const tableData = setFilteredDataSource.map((item) => ({
  //   awb_no: item.awb_no,
  //   inboundAmount: item.bound === "inbound" ? item.amount : null,
  //   outboundAmount: item.bound === "outbound" ? item.amount : null,
  // }));

  // // Add total row to the table data
  // tableData.push({
  //   awb_no: "Total",
  //   inboundAmount,
  //   outboundAmount,
  // });

  const renderSummary = () => {
    const totalDrAmount = filteredDataSource.reduce((acc, item) => {
      if (item.dr_or_cr === "Dr") {
        return acc + item.amount;
      }
      return acc;
    }, 0);
    const totalCrAmount = filteredDataSource.reduce((acc, item) => {
      if (item.dr_or_cr === "Cr") {
        return acc + item.amount;
      }
      return acc;
    }, 0);

    // const totalTaxAmount = data.reduce((acc, item) => acc + item.taxAmount, 0);
    // const totalDiscount = data.reduce((acc, item) => acc + item.discount, 0);
    return (
      <>
        <Table.Summary.Row className="my-summary-row">
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell"></Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b>Total</b>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {Number(totalDrAmount).toFixed(2)}
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {Number(totalCrAmount).toFixed(2)}
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="cell">
            {" "}
            <b style={{ paddingLeft: "9px" }}>
              {(Number(totalDrAmount) - Number(totalCrAmount)).toFixed(2)}
            </b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  const handlePrint = (record) => {
    // Handle printing logic here
    // You can use a library like react-to-print to facilitate the printing process
    // Example: https://www.npmjs.com/package/react-to-print
    componentRef.current.handlePrint();
  };

  const expandedRowRender = (record) => {
    // const sortedPayment = record.payment.sort((a, b) => {
    //   // Assuming 'trans_date' is the property that contains the date
    //   const dateA = new Date(a.trans_date);
    //   const dateB = new Date(b.trans_date);

    //   return dateA - dateB; // Sort in ascending order
    // });
    const columns = [
      {
        title: "#",
        key: "index",
        width: "20px",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Box No",
        dataIndex: "box_number",
        key: "box_number",
        // render: (text, record, index) => (
        //   <div className="data-table-items">
        //     {record.payment && record?.payment.account_no}
        //   </div>
        // ),
      },
      {
        title: "Chargable Weight",
        dataIndex: "chargable_weight",
        key: "chargable_weight",
      },
      // {
      //   title: "Packing Charge",
      //   dataIndex: "packing_charge",
      //   key: "packing_charge",
      // },
      {
        title: "Invoice Charge",
        dataIndex: "",
        key: "",
        render: (record) => {
          let total = record.total_charge - record.packing_charge;
          return total;
        },
      },
      {
        title: "Paid Amount",
        render: (record) => {
          return;
        },
      },
      {
        title: "Balance",
        render: (record) => {
          return;
        },
      },
      {
        title: "Discount",
        render: (record) => {
          return;
        },
      },
    ];
    const columpayment = [
      {
        title: "#",
        key: "index",
        width: "20px",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Account No",
        dataIndex: "account_no",
        key: "account_no",
      },
      { title: "Description", dataIndex: "description", key: "description" },

      { title: "Amount", dataIndex: "amount", key: "amount" },
    ];

    // Replace with your desired target date in the same format as the "invoice_date"

    const paymentSummery = (record) => {
      console.log("record", record);
      let amount = 0;
      let debitTotal = 0;
      record.forEach((value) => {
        amount += value.amount;
        // debitTotal += value.debit;
      });
      return (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell></Table.Summary.Cell>
            <Table.Summary.Cell align="right">
              <b>Total</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell align="center">
              {/* <Text type="danger">{Number(creditTotal).toFixed(2)}</Text> */}
            </Table.Summary.Cell>
            <Table.Summary.Cell align="center">
              <Text type="danger">{Number(amount).toFixed(2)}</Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      );
    };
    return (
      // <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
      <Tabs
        onChange={(e) => {
          // console.log(
          //   "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
          //   record.parent ? record.parent.id : record.id
          // );
          // props.getTrackData(e)
        }}
        tabPosition="left"
        style={{ margin: "10px" }}
      >
        <TabPane tab="Items Details" key="tab1">
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <h5>Items Details</h5>
            <p>Total box and details..</p>
            <Table
              className="table_item"
              style={{
                padding: "20px",
              }}
              bordered
              size="small"
              columns={columns}
              dataSource={record.boxes}
              pagination={false}
            />
          </div>
        </TabPane>

        <TabPane tab="Payment Details" key="tab2">
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <h5>Payment Details</h5>
            <p>All payment details</p>
            <Table
              className="table_item"
              style={{
                padding: "20px",
              }}
              bordered
              size="small"
              columns={columpayment}
              dataSource={record.payment}
              summary={paymentSummery}
              // dataSource={record.payment.filter(
              //   (item) => item.trans_type === "PY"
              // )}
              pagination={false}
            />
          </div>
        </TabPane>

        {/* Add more TabPanes as needed */}
      </Tabs>
    );
  };
  return (
    <div>
      <Form
        form={form}
        layout="inline"
        onFinish={onFinish}
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "center",
          border: "1px solid #ccc",
          padding: "16px",
        }}
      >
        <Form.Item name="dateRange">
          <RangePicker size="small" style={{ width: 240 }} />
        </Form.Item>
        <Form.Item
          name="awb_no"
          label="Awb No"
          rules={[{ required: false, message: "required" }]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>
        <Form.Item>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            // onClick={handleSearch}
            loading={loading}
          >
            Search
          </Button>
        </Form.Item>
        <Form.Item>
          <ReactToPrint
            trigger={() => (
              <Button
                size="small"
                type="primary"
                icon={<PrinterOutlined />}
                style={{ marginLeft: 8 }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Form.Item>
      </Form>
      <div ref={componentRef} style={{ margin: "20px 20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <>sajeer</>
          <b>Date Range:</b> {moment(dateRange[0]).format("DD-MM-YYYY")} to{" "}
          {moment(dateRange[1]).format("DD-MM-YYYY")}
        </div>

        {/* <Table
          size="small"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={false}
          bordered
          summary={renderSummary}
        /> */}
        <Table
          dataSource={filteredDataSource}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
          expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
          // footer={() => `Total Charge Sum: ${totalChargeSum}`}
        />
      </div>
    </div>
  );
};

export default AwbReports;
