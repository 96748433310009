import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table, Row, Col, Container } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
import "./style.css";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";

function PrintBarcodeModal(props) {
  const componentRef = useRef();
  const [loading, setLoading] = useState(true);
  const [collectiond, setCollectiond] = useState([]);

  useEffect(() => {
    CollectionDetails();
  }, []);

  const CollectionDetails = () => {
    try {
      axios
        .get(
          API.BASR_URL +
            API.PICKUPCOLLECTIONS_ACTIONS +
            props.item.collection_id,
          {
            headers: Headers(),
          }
        )
        .then(function (res) {
          if (res.status === 200) setCollectiond(res.data);
          setLoading(false);
          message.success("Success");
        })
        .catch(function (error) {
          setLoading(false);
          message.error("fetching collection details failed");
        });
    } catch (err) {
      console.log(err);
    }
  };

  let requestData = {
    is_barcode_label_print: true,
  };

  const isPrinted = () => {
    try {
      axios({
        method: "put",
        url:
          API.BASR_URL +
          API.BOXES_ACTIONS +
          "print/" +
          props.item.box_number +
          "/is_barcode_label_print",
        headers: Headers(),
        data: requestData,
      })
        .then(function (response) {
          setLoading(false);
          if ([200, 201].includes(response.status)) {
            message.success("Success");
            props.onClose();
            props.reload();
          }
        })
        .catch(function (error) {
          setLoading(false);
          message.error("");
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.onClose()}
      width={470}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <br />
          <div ref={componentRef} className="print-box-label-container">
            <table className="print-box-label-table">
              <tr>
                <td colspan={2}>
                  <div className="print-box-label-txt1">
                    {props?.item?.user &&
                      props?.item?.user?.branch &&
                      props?.item?.user?.branch?.name}
                  </div>
                </td>
                <td></td>
                <td colspan={2}>
                  <div>
                    <div className="print-box-label-txt2">
                      BARCODE: {props?.item?.box_number}
                    </div>
                    <div className="print-box-label-txt2">
                      SDATE:{" "}
                      {props?.item &&
                        moment(props?.item?.createdAt).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div style={{ borderBottom: "3px solid black" }} />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className="print-box-label-txt3">FROM</div>
                  <div className="print-box-label-txt4">
                    {collectiond?.sender_name},{collectiond?.sender_address},
                    {collectiond?.sender_phone}
                  </div>
                  {/* <div className="print-box-label-txt4">
                    {collectiond.sender_address}
                  </div>
                  <div className="print-box-label-txt4">
                    {collectiond.sender_phone}
                  </div> */}
                </td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className="print-box-label-txt3">DELIVER TO</div>
                  <div className="print-box-label-txt4">
                    {collectiond?.reciver_name},{collectiond?.reciver_address},{" "}
                    {collectiond?.sender_phone}
                  </div>
                  {/* <div className="print-box-label-txt4">
                    {collectiond.reciver_name}
                  </div>
                  <div className="print-box-label-txt4">
                    {collectiond.reciver_phone}
                  </div> */}
                </td>
                <td colSpan={1}></td>
              </tr>

              <tr>
                <td colSpan={5}>
                  <div className="print-box-label-txt3"> TRACKING NO:</div>
                  <div className="print-box-label-txt1">
                    {props?.item?.box_number}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div style={{ borderBottom: "1px solid black" }} />
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="print-box-label-row">
                    <div className="print-box-label-txt5">NO OF PCS: 1</div>

                    <div className="print-box-label-txt5">
                      CHG WEIGHT: {props?.item?.chargable_weight + ".kg"}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div style={{ borderBottom: "1px solid black" }} />
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="print-box-label-row">
                    <div className="print-box-label-txt5">
                      BOX SIZE:{" "}
                      {props?.item.breadth +
                        "x" +
                        props?.item.length +
                        "x" +
                        props?.item.height}
                    </div>
                    <div className="print-box-label-txt5">
                      : {props?.item.name}
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={5}>
                  <div style={{ borderBottom: "1px solid black" }} />
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="text-center">
                    <Barcode
                      value={props?.item?.box_number}
                      height="90"
                      font="Montserrat-Regular"
                      width="3"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="text-center print-box-label-txtMax">
                    {props?.item?.service && props?.item?.service.name}
                  </div>
                </td>
              </tr>
              {/* <tr>
                <td colspan={2}>
                  <div className="print-box-label-txt1">
                    {props.item && props.item.service_type}
                  </div>
                </td>
              </tr> */}
            </table>
          </div>
          <br />
          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onClose()} block>
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="primary"
                    block
                    onClickCapture={() => {
                      isPrinted();
                    }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef?.current}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default PrintBarcodeModal;
