import React, { useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tabs,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../Config";
import axios from "axios";
import { useSelector } from "react-redux";

const SalespaymentModal = (props) => {
  const taxGoDatas = useSelector((state) => state.TaxgoSlice);
  const Auth = useSelector((state) => state.Auth.user);

  const [loading, setLoading] = useState(false);
  const { TabPane } = Tabs;
  const formRef = useRef();

  const selectedRows = props?.selectedRows || [];

  const totalSum = selectedRows?.reduce(
    (sum, row) => sum + row?.total_charge,
    0
  );

  const onFinish = async (val) => {
    try {
      setLoading(true);
      const { userID, companyID, staff_id, counterid, token } =
        taxGoDatas?.data || {};
      const staffTransactions = selectedRows?.map((box) => {
        const totalCharge = Number(box?.total_charge);
        return {
          id: box?.taxgo_transaction_id,
          adminid: userID,
          companyid: companyID,
          ledger: "47",
          ledgercategory: "3",
          type: "Customer Receipt",
          usertype: "staff",
          paid_amount: totalCharge,
          total: totalCharge,
          outstanding: "0.00",
          saleid: Number(box?.taxgo_new_saleid),
          customerid: staff_id,
          shiftid: 318,
          invoiceno: box?.taxgo_sale_invoicenumber,
          paymethod: val?.account_no,
          // paymethod: "cash",
          staffid: staff_id,
          status: "open",
          paid_status: 2,
          sdate: new Date(),
          counterid: counterid,
        };
      });

      const obj = {
        staffTransactions,
        bankid: 24410,
        paidmethod: val?.account_no,
        // paidmethod: "cash",
        date: new Date(),
        reference: "ref",
      };

      const response = await axios.post(
        `${API.TAXGO_BASE_URL}${API.CLOSE_TRANSACTION_PAYMENT_TAXGO}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        await updateAmountField();
        message.success("Payment Success");
        props.reload()
        props.onCancel();
      } else {
        message.error("Payment Failed!");
      }
    } catch (error) {
      message.error("Oops. Something went wrong");
      console.error("Payment error:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateAmountField = async () => {
    const { token } = taxGoDatas?.data || {};
    try {
      const obj = selectedRows?.map((box) => ({
        box_number: box?.box_number,
        staff_paid: true,
        adminId: Auth?.id,
      }));

      const response = await axios.put(
        `${API.BASR_URL}${API.BOXUPDATEPAYMENT}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("====================response================");
      console.log(response);
      console.log("===================response=================");
      setLoading(false);
    } catch (error) {
      message.error(`Oops. Something went wrong ${error}`);
      console.error("Update amount field error:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      width={650}
      footer={false}
      className="rounded"
      centered
    >
      <div className="PaymentFormModal-box1">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Make Reciept" key="1">
            <br />
            <div className="PaymentFormModal-txt1">Make Reciept </div>
            <br />
            <div className="d-flex justify-content-between align-items-center">
              <div className="sales-report-box2 blue">
                <p className="sales-report-txt1">TOTAL:</p>
                <p className="sales-report-txt2">{totalSum}</p>
              </div>
              {/* <div className="sales-report-box2 green">
                <p className="sales-report-txt1">PAID: </p>
                <p className="sales-report-txt2">{paidAmount}</p>
              </div>
              <div className="sales-report-box2 red">
                <p className="sales-report-txt1">BALANCE: </p>
                <p className="sales-report-txt2">{balance}</p>
              </div> */}
            </div>

            <br />
            <div>
              <Form
                ref={formRef}
                onFinish={onFinish}
                // onValuesChange={onValuesChange}
              >
                <div className="PaymentFormModal-txt2">Reciept Date</div>
                <Form.Item
                  name="payment_date"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker placeholder="Pick a date" className="w-100" />
                </Form.Item>

                <div className="PaymentFormModal-txt2">Payment Method</div>
                <Form.Item
                  name="account_no"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select placeholder="Select Account Type">
                    <Select.Option value="cash">Cash</Select.Option>
                    <Select.Option value="bank">Bank</Select.Option>
                  </Select>
                </Form.Item>
                {/* <Select
                    showSearch
                    filterOption={(input, option) => {
                      const accountNo = option.value.toLowerCase();
                      const accountName = option.children
                        .toString()
                        .toLowerCase();
                      return (
                        accountNo.includes(input.toLowerCase()) ||
                        accountName.includes(input.toLowerCase())
                      );
                    }}
                    className="w-100"
                    placeholder="Select Account"
                  >
                    {props?.accounts?.map((item) => (
                      <Select.Option
                        key={item.account_no}
                        value={item.account_no}
                      >
                        {item.account_name}
                      </Select.Option>
                    ))}
                  </Select> */}

                <div className="PaymentFormModal-txt2">Amount</div>
                <Form.Item
                  name="amount"
                  initialValue={totalSum}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input disabled />
                </Form.Item>

                <div className="PaymentFormModal-txt2">Remarks</div>
                <Form.Item name="note">
                  <Input />
                </Form.Item>
                <Row>
                  <Col sm="6" xs="12">
                    <Button block onClick={() => props.onCancel()} size="large">
                      Close
                    </Button>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item>
                      <Button
                        type="primary"
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};

export default SalespaymentModal;
