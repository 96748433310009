import { useState, useEffect } from "react";
import { Pagination, Table } from "antd";
import moment from "moment";
const SalesDataTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (props.onSelect) {
      props.onSelect(newSelectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  useEffect(() => {
    if (props.data && props.data.length === 1) {
      const singleRow = props.data[0];
      const rowKey = singleRow?.box_number;
      setSelectedRowKeys([rowKey]);
      if (props.onSelect) {
        props.onSelect(props.data);
      }
    }
  }, [props.data]);

  const columns = [
    {
      title: "DATE",
      dataIndex: "createdat",
      key: "createdat",
      render: (createdat) => (
        <div className="collection-table-item">
          {moment(createdat).format("l")}
        </div>
      ),
    },
    {
      title: "BOX NO",
      dataIndex: "box_number",
      key: "box_number",
      render: (text, record) => <div>{record?.box_number}</div>,
    },
    {
      title: "WEIGHT",
      dataIndex: "chargable_weight",
      key: "chargable_weight",
      render: (text, record) => <div>{record?.chargable_weight}</div>,
    },
    {
      title: "RATE",
      dataIndex: "total_charge",
      key: "total_charge",
      render: (text, record) => <div>{record?.total_charge}</div>,
    },
    {
      title: "PAID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        const crAmount = record?.collection?.ledgerTransColln?.reduce(
          (total, transaction) => {
            if (
              transaction.cr_or_dr === "Cr" &&
              !isNaN(Number(transaction.amount))
            ) {
              return total + Number(transaction.amount);
            }
            return total;
          },
          0
        );

        return crAmount ? crAmount.toFixed(2) : "0.00";
      },
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => {
        const totalRoundOff = record?.collection?.ledgerTransColln?.reduce(
          (sum, transaction) => sum + (transaction.round_off || 0),
          0
        );
        return <div>{totalRoundOff || 0}</div>;
      },
    },

    {
      title: "BALANCE",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record?.collection?.ledgerTransColln?.filter(
          (transaction) => transaction.colln_id === collnId
        );

        const crAmount = transactions?.reduce((total, e) => {
          if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        const drAmount = transactions?.reduce((total, e) => {
          if (e.cr_or_dr === "Dr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        const balance = drAmount - crAmount;

        return <span style={{ color: "red" }}>{balance?.toFixed(2)}</span>;
      },
    },
    {
      title: "CREATED BY",
      dataIndex: "",
      key: "",
      render: (text, record) => <div>{record?.created_user?.name}</div>,
    },
  ];

  return (
    <div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props?.data}
        pagination={false}
        size="small"
        rowKey={(record) => {
          return record?.box_number;
        }}
        rowSelection={rowSelection}
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props?.meta?.page}
          total={props?.meta?.itemCount}
          pageSize={props?.meta?.take || 0}
          onChange={props?.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
};

export default SalesDataTable;
