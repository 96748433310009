import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Image from "../../Assets/Images/S5check-outline.gif";
import "./ModalStyle.css";

import {
  Form,
  Button,
  Input,
  message,
  Select,
  DatePicker,
  Drawer,
  Checkbox,
  Space,
  notification,
  Modal,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiMinusCircle } from "react-icons/bi";
import styles from "./styles";
import "./style.css";

const { Option } = Select;
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("post");

  const [transit, setTransit] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      loggin_branch_id: Auth?.branch?.name,
    });
  }, []);

  const onFinish = (values) => {
    console.log(props.item);
    console.log(values);
    setLoading(true);
    let requestData = {
      date: new Date(),
      login_id: parseInt(Auth.id),
      loggin_branch_id: parseInt(Auth.branch_id),
      to_branch_id: parseInt(values.to_branch),
      loadded_time: moment(values.loadded_time),
      expected_arrival_time: moment(values.expected_arrival_time),
      truck_no: values.truck_no,
      driver_name: values.driver_name,
      stops: values.stops,
      deported_time: moment(values.deported_time),
      trip_amount: parseInt(values.trip_amount),
      paid_amount: parseInt(values.paid_amount),
      expected_vehicle_charge: parseInt(values.expected_vehicle_charge),
      trip_status: "start",
    };

    let URL =
      props.mode === "post"
        ? API.TRIP_ACTIONS
        : API.TRIP_ACTIONS + props.selected.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestData,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          // props.onchange();
          // props.onClose();
          setShowModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        notification.open({
          message: "ERROR",
          description: `${error.response.data.message}`,
        });
      });
  };

  const onChange = (e) => {
    setTransit(e.target.checked);
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Trip
        </span>
      }
      width={innerwidth ? width : width / 2.4}
      onClose={() => props.onClose()}
      visible={props.visible}
      onCancel={() => props.onClose()}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          login_id: props.selected && props.selected.login_id,
          loggin_branch_id: props.selected && props.selected.loggin_branch_id,
          to_branch_id: props.selected && props.selected.to_branch_id,
          loadded_time: props.selected && moment(props.selected.loadded_time),
          deported_time: props.selected && moment(props.selected.deported_time),
          expected_arrival_time:
            props.selected && moment(props.selected.expected_arrival_time),
          truck_no: props.selected && props.selected.truck_no,
          driver_name: props.selected && props.selected.driver_name,
          trip_amount: props.selected && props.selected.trip_amount,
          paid_amount: props.selected && props.selected.paid_amount,
          expected_vehicle_charge:
            props.selected && props.selected.expected_vehicle_charge,
          stops: [{ key: 0 }],
        }}
        ref={formRef}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="loggin_branch_id"
                label={<div className="form-item-label">From branch</div>}
              >
                <Select
                  disabled
                  placeholder=""
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  style={{ borderRadius: 4 }}
                >
                  {props.branches.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="to_branch"
                label={<div className="form-item-label">To Branch</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  placeholder="To branch"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.branches.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="truck_no"
                label={<div className="form-item-label">Truck Number</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="driver_name"
                label={<div className="form-item-label">Truck Driver</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="loadded_time"
                label={<div className="form-item-label">Loaded Time</div>}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder="select date time"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="deported_time"
                label={<div className="form-item-label">Deported Time</div>}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder="select date time"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="expected_arrival_time"
                label={
                  <div className="form-item-label">Expected Arrival Time</div>
                }
                rules={[{ required: false, message: "required" }]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  placeholder="select Expected Arrival time"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="expected_vehicle_charge"
                label={
                  <div className="form-item-label">Expected Vehicle Charge</div>
                }
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Checkbox onChange={onChange} style={{ marginBottom: 8 }}>
              <div className="form-item-label"> Include Transit</div>
            </Checkbox>

            {transit ? (
              <Form.List name="stops">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 0,
                        }}
                        align="baseline"
                      >
                        <Form.Item {...restField} name={[name, "stop"]}>
                          <Input
                            placeholder={"Enter Stop " + "" + (key + 1)}
                            style={styles.TripFormTxt1}
                          />
                        </Form.Item>

                        <BiMinusCircle onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        size="small"
                        onClick={() => add()}
                        icon={<AiOutlinePlusCircle />}
                      >
                        <span className="trip-form-button-label">Add Stop</span>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            ) : null}
          </Row>

          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="12">
              <Button
                htmlType="submit"
                size="medium"
                block
                loading={loading}
                type="primary"
                style={styles.NextButton}
              >
                Submit
              </Button>
            </Col>
            <Col sm="3" xs="12">
              <Button
                onClick={() => props.onClose()}
                size="medium"
                block
                style={styles.BackButton}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal
        footer={null}
        centered
        closable={null}
        width={400}
        open={showModal}
      >
        <div className="modalContent">
          <img src={Image} style={{ width: "50%" }} />
          <p className="fw-bold">Trip Booked</p>
          <Button
            size="medium"
            style={styles.NextButton}
            type="primary"
            onClick={() => {
              setShowModal(false);
              props.onchange();
              props.onClose();
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    </Drawer>
  );
}
export default FormModal;
