import {
  Button,
  Input,
  Pagination,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { AiOutlineMobile, AiOutlineShareAlt } from "react-icons/ai";
import { FaInternetExplorer } from "react-icons/fa";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlinePayments,
  MdOutlineRemoveRedEye,
  MdSearch,
} from "react-icons/md";

function DataTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button
          type="primary"
          size="small"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <MdSearch />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) =>
        (props.meta.page - 1) * props.meta.take + index + 1,
    },
    {
      title: "",
      render: (text, record) => (
        <div>
          {record.created_from && record.created_from === "web" ? (
            <FaInternetExplorer size={15} />
          ) : (
            <AiOutlineMobile size={15} />
          )}
        </div>
      ),
    },
    {
      title: "DATE",
      dataIndex: "createdat",
      key: "createdat",
      render: (createdat) => (
        <div className="collection-table-item">
          {moment(createdat).format("l")}
        </div>
      ),
    },
    {
      title: "BOOKING",
      dataIndex: "",
      key: "",
      render: (text, record, index) => (
        <Tooltip
          title={
            <div style={{ fontFamily: "Campton-Light" }}>
              {record.boxes &&
                record.boxes.map((item, index) => {
                  return <div>{`BOX ${index + 1} : ${item.box_number}`}</div>;
                })}
            </div>
          }
        >
          <div className="collection-table-column">
            {record.boxes &&
              record.boxes.map((item, index) => {
                return (
                  <div
                    className="collection-table-item2"
                    style={{ fontSize: "11px" }}
                  >
                    {item.box_number}
                  </div>
                );
              })}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "PCS",
      dataIndex: "number_boxes",
      key: "number_boxes",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      title: "TOTAL KILO",
      dataIndex: "total_weight",
      key: "total_weight",
      render: (text) => <div className="collection-table-item">{text}</div>,
      width: 50,
    },
    {
      title: "AMOUNT",
      dataIndex: "total_pay",
      key: "total_pay",
      render: (text, record, index) => (
        <>
          {record.total_amount
            ? record.total_amount
            : record.boxes &&
              record.boxes.reduce((tot, arr) => {
                return Number(tot) + Number(arr.total_charge);
              }, 0)}
        </>
      ),
    },
    {
      title: "PAID",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record.ledgerTransColln.filter(
          (transaction) => transaction.colln_id === collnId
        );

        const crAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        return crAmount.toFixed(2);
      },
    },
    // {
    //   title: "BALANCE",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (collnId, record) => {
    //     const transactions = record.ledgerTransColln.filter(
    //       (transaction) => transaction.colln_id === collnId
    //     );
    //     const crAmount = transactions.reduce((total, e) => {
    //       if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
    //         return total + parseFloat(e.amount);
    //       }
    //       return total;
    //     }, 0);

    //     const drAmount = transactions.reduce((total, e) => {
    //       if (e.cr_or_dr === "Dr" && !isNaN(parseFloat(e.amount))) {
    //         return total + parseFloat(e.amount);
    //       }
    //       return total;
    //     }, 0);

    //     const balance = Number(drAmount) - Number(crAmount);
    //     return <span style={{ color: "red" }}>{balance.toFixed(2)}</span>;
    //   },
    // },
    {
      title: "BALANCE",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const totalAmount = record.total_amount
          ? Number(record.total_amount)
          : record.boxes
          ? record.boxes.reduce(
              (tot, arr) => Number(tot) + Number(arr.total_charge),
              0
            )
          : 0;

        const transactions = record.ledgerTransColln.filter(
          (transaction) => transaction.colln_id === collnId
        );

        const crAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        const balance = totalAmount - crAmount;

        return (
          <span style={{ color: balance < 0 ? "red" : "black" }}>
            {balance.toFixed(2)}
          </span>
        );
      },
    },

    {
      title: "STATUS",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record.ledgerTransColln.filter(
          (transaction) => transaction.colln_id === collnId
        );

        if (transactions.length === 0) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="default">
              No Trans
            </Tag>
          );
        }

        const drAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Dr") {
            const amount = parseFloat(e.amount) || 0;
            const roundOff = parseFloat(e.round_off) || 0;
            return total + amount + roundOff;
          }
          return total;
        }, 0);

        const crAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Cr") {
            const amount = parseFloat(e.amount) || 0;
            const roundOff = parseFloat(e.round_off) || 0;
            return total + amount + roundOff;
          }
          return total;
        }, 0);

        if (drAmount === crAmount) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="success">
              Complete
            </Tag>
          );
        } else if (crAmount > 0) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="processing">
              Partial
            </Tag>
          );
        } else {
          return (
            <Tag style={{ borderRadius: "10px" }} color="warning">
              Not Paid
            </Tag>
          );
        }
      },
    },
    {
      title: "SENDER",
      dataIndex: "sender_name",
      key: "sender_name",
      render: (text) => <div className="collection-table-item">{text}</div>,
      ...getColumnSearchProps("sender_name"),
      width: 150,
    },
    {
      title: "RECIEVER",
      dataIndex: "reciver_name",
      key: "reciver_name",
      render: (text) => <div className="collection-table-item">{text}</div>,
      ...getColumnSearchProps("reciver_name "),
      width: 150,
    },

    {
      title: "LOG ID",
      dataIndex: "collected_by",
      key: "collected_by",
      render: (_, record) => {
        const userName = record?.boxes?.[0]?.created_users?.name || "";
        return <div className="collection-table-item">{userName}</div>;
      },
    },
    {
      title: "LOG BRANCH",
      dataIndex: "",
      key: "",
      render: (text, record, index) => (
        <div className="collection-table-item">
          {record.boxes &&
            record.boxes[0] &&
            record.boxes[0]?.created_users?.branch?.name}
        </div>
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      render: (item) => (
        <div className="tableAction">
          <Button size="small" onClick={() => props.payment(item)} type="link">
            <MdOutlinePayments size={20} color={"green"} />
          </Button>
          <Button size="small" onClick={() => props.details(item)} type="link">
            <MdOutlineRemoveRedEye size={20} color={"#78909c"} />
          </Button>
          <Button size="small" type="link" onClick={() => props.share(item)}>
            <AiOutlineShareAlt size={20} />
          </Button>
          <Button size="small" onClick={() => props.update(item)} type="link">
            <MdOutlineEdit size={20} color={"#ffa726"} />
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small" type="link">
              <MdOutlineDelete size={20} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
        rowKey={(record) => record.id}
        rowSelection={{ type: "radio", ...rowSelection }}
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;
