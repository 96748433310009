import React from "react";
import { Table } from "antd";
import moment from "moment";

import { Image } from "antd";

const TrackItem = (props) => {
  const columns = [
    {
      title: "BRANCH",
      width: 250,
      dataIndex: "name",
      render: (text, record, index) => (
        <div>{record?.user?.branch?.name?.toUpperCase()}</div>
      ),
    },

    {
      title: "USER",
      width: 120,
      render: (text, record, index) => <div>{record?.user?.name}</div>,
    },

    {
      title: "DATE & TIME",
      width: 230,
      dataIndex: "created_at",
      render: (text, record, index) => (
        <div>{moment(record?.created_at)?.format("lll")}</div>
      ),
    },

    {
      title: "STATUS & REMARK",
      render: (text, record, index) => {
        return (
          <>
            <div style={{ fontSize: 15 }}>
              {record?.box_statuss?.status_head}{" "}
              {record?.box_status === "44"
                ? `${
                    record?.agent !== "" &&
                    record?.agent !== null &&
                    record?.agent !== "null" &&
                    record?.agent
                  }`
                : ""}
              <span className="DetailedTracking-txt3">
                {record?.box_status === "20" || record?.box_status === "32"
                  ? props?.data?.awb_xray_agent?.name
                  : null}{" "}
              </span>
              <span className="DetailedTracking-txt3">
                {record?.box_status === "19"
                  ? props?.data?.third_party_trans?.thirdPartySenders?.name
                  : null}
              </span>
              <span className="DetailedTracking-txt3">
                {record?.box_status === "13"
                  ? `${
                      props?.data?.created_user?.branch?.name &&
                      ": " + props?.data?.created_user?.branch.name
                    }`
                  : ""}

                {/* destination removed from the header of match status ===13 in box new table */}
                {record?.box_status === "7"
                  ? ` ${
                      props?.data?.airway_bill?.awb_no &&
                      props?.data?.airway_bill?.airline +
                        props?.data?.airway_bill?.awb_no
                    } TO ${
                      props?.data?.service?.name && props?.data?.service?.name
                    }`
                  : ""}
              </span>
              {record?.box_status === "6"
                ? ` with Trip.No: ${
                    props?.data?.trip_no && props?.data?.trip_no
                  }`
                : ""}
            </div>
            {record?.remarks === "NULL" || null ? null : (
              <div style={{ fontSize: 15 }}>
                <b>{record?.remarks}</b>
              </div>
            )}

            {record?.url?.length ? (
              <div style={{ fontSize: 15 }}>
                <a target="_blank" rel="noopener noreferrer" href={record?.url}>
                  Track
                </a>
              </div>
            ) : null}
          </>
        );
      },
    },

    {
      title: "WEIGHT",
      width: 100,
      dataIndex: "created_at",
      render: (text, record, index) => <div>{record?.weight}</div>,
    },

    {
      title: "IMAGES",
      width: 200,
      render: (text, record, index) => (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            {record?.image_1 && <Image width={50} src={record?.image_1} />}
            {record?.image_2 && <Image width={50} src={record?.image_2} />}
            {record?.image_3 && <Image width={50} src={record?.image_3} />}
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            {record?.image_4 && <Image width={50} src={record?.image_4} />}
            {record?.image_5 && <Image width={50} src={record?.image_5} />}
            {record?.image_6 && <Image width={50} src={record?.image_6} />}
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={props?.data?.boxStatus?.sort(
          (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
        )}
        pagination={false}
        bordered
      />
      <br />
    </div>
  );
};

export default TrackItem;
